// Imports
@import '../../styles/styles.sass'

// Block
.v-simple-checkbox
  align-self: center
  line-height: normal
  position: relative
  user-select: none
  cursor: pointer

// Modifiers
.v-simple-checkbox--disabled
    cursor: default
