// Imports
@import './_variables.scss'

// Theme
+theme(v-data-table) using ($material)
  tbody
    tr
      &.v-data-table__selected
        background: map-deep-get($material, 'table', 'active')

  .v-row-group
    &__header, &__summary
      background: map-deep-get($material, 'table', 'group')

  .v-data-footer
    border-top: thin solid map-get($material, 'dividers')

  .v-data-table__empty-wrapper
    color: map-deep-get($material, 'text', 'disabled')

.v-data-table
  border-radius: $data-table-border-radius

  tbody
    tr
      &.v-data-table__expanded
        border-bottom: 0

      &.v-data-table__expanded__content
        box-shadow: $data-table-expanded-content-box-shadow

  .v-data-table__mobile-table-row
    display: initial

  .v-data-table__mobile-row
    height: initial
    min-height: $data-table-mobile-row-min-height

.v-data-table__empty-wrapper
  text-align: center

.v-data-table__mobile-row
  align-items: center
  display: flex
  justify-content: space-between

  &__header
    font-weight: $data-table-mobile-row-header-font-weight

  &__cell
    +ltr()
      text-align: right

    +rtl()
      text-align: left

.v-row-group
  &__header, &__summary

    td
      height: $data-table-row-group-children-td-height

.v-data-table__expand-icon
  user-select: none
  cursor: pointer

  &--active
    transform: rotate(-180deg)
