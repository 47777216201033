@import '../../styles/styles.sass';

$skeleton-loader-actions-button-margin: 12px !default;
$skeleton-loader-actions-padding: 16px 16px 8px !default;
$skeleton-loader-article-heading-margin-top-left: 16px !default;
$skeleton-loader-article-heading-margin-x: 16px !default;
$skeleton-loader-article-paragraph-padding: 16px !default;
$skeleton-loader-avatar-height: 48px !default;
$skeleton-loader-avatar-height: 56px !default;
$skeleton-loader-avatar-width: 48px !default;
$skeleton-loader-border-radius: $border-radius-root !default;
$skeleton-loader-button-border-radius: $border-radius-root !default;
$skeleton-loader-button-height: 36px !default;
$skeleton-loader-button-width: 64px !default;
$skeleton-loader-card-heading-loader-heading-margin: 16px !default;
$skeleton-loader-card-text-padding: 16px !default;
$skeleton-loader-chip-border-radius: 16px !default;
$skeleton-loader-chip-height: 32px !default;
$skeleton-loader-chip-width: 96px !default;
$skeleton-loader-date-picker-border-radius: inherit !default;
$skeleton-loader-date-picker-days-height: 40px !default;
$skeleton-loader-date-picker-days-margin: 4px !default;
$skeleton-loader-date-picker-days-padding: 0 12px !default;
$skeleton-loader-date-picker-days-width: 40px !default;
$skeleton-loader-date-picker-options-avatar-child-margin: 8px !default;
$skeleton-loader-date-picker-options-avatar-height: 40px !default;
$skeleton-loader-date-picker-options-avatar-width: 40px !default;
$skeleton-loader-date-picker-options-padding: 16px !default;
$skeleton-loader-divider-border-radius: 1px !default;
$skeleton-loader-divider-height: 2px !default;
$skeleton-loader-heading-border-radius: 12px !default;
$skeleton-loader-heading-height: 24px !default;
$skeleton-loader-image-height: 200px !default;
$skeleton-loader-item-avatar-height: 40px !default;
$skeleton-loader-item-avatar-margin: 16px !default;
$skeleton-loader-item-avatar-width: 40px !default;
$skeleton-loader-item-padding: 0 16px !default;
$skeleton-loader-item-three-line-height: 88px !default;
$skeleton-loader-item-two-line-height: 72px !default;
$skeleton-loader-list-item-height: 48px !default;
$skeleton-loader-loading-animation: loading 1.5s infinite !default;
$skeleton-loader-loading-transform: translateX(-100%) !default;
$skeleton-loader-table-cell-height: 48px !default;
$skeleton-loader-table-cell-width: 88px !default;
$skeleton-loader-table-heading-padding: 16px !default;
$skeleton-loader-table-tbody-padding: 16px 16px 0 !default;
$skeleton-loader-table-tfoot-avatar-height: 40px !default;
$skeleton-loader-table-tfoot-avatar-width: 40px !default;
$skeleton-loader-table-tfoot-children-margin: 8px !default;
$skeleton-loader-table-tfoot-padding: 16px !default;
$skeleton-loader-table-thead-heading-padding: 16px !default;
$skeleton-loader-table-thead-padding: 16px !default;
$skeleton-loader-text-border-radius: 6px !default;
$skeleton-loader-text-height: 12px !default;
