@import '../../styles/styles.sass';

$tooltip-background-color: rgba(map-get($grey, 'darken-2'), 0.9) !default;
$tooltip-text-color: map-get($shades, 'white') !default;
$tooltip-border-radius: $border-radius-root !default;
$tooltip-font-size: 14px !default;
$tooltip-transition-timing-function: map-get($transition, 'linear-out-slow-in') !default;
$tooltip-transition-enter-duration: 150ms !default;
$tooltip-transition-leave-duration: 75ms !default;
$tooltip-padding: 5px 16px !default;
