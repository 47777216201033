@import '../../styles/styles.sass';

$input-font-size: 16px !default;
$input-letter-spacing: normal !default;
$input-text-align: left !default;
$input-max-height: 32px !default;
$input-label-height: 20px !default;
$input-label-letter-spacing: 20px !default;
$input-prepend-append-outer-margin: 9px !default;
$input-icon-height: 24px !default;
$input-icon-min-width: 24px !default;
$input-icon-width: 24px !default;
$input-slot-margin-bottom: 8px !default;
$input-dense-slot-margin-bottom: 4px !default;
