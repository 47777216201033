@import '../../styles/styles.sass';

$switch-dirty-offset-x: 20px !default;
$switch-disabled-opacity: .6 !default;
$switch-ripple-dense-top: calc(50% - 22px) !default;
$switch-ripple-dense-x: -12px !default;
$switch-ripple-top: calc(50% - 24px) !default;
$switch-ripple-x: -14px !default;
$switch-thumb-dense-height: 18px !default;
$switch-thumb-dense-width: 18px !default;
$switch-thumb-elevation: 4 !default;
$switch-thumb-height: 20px !default;
$switch-thumb-top: calc(50% - 10px) !default;
$switch-thumb-width: 20px !default;
$switch-track-border-radius: 8px !default;
$switch-track-dense-height: 12px !default;
$switch-track-dense-inset-height: 22px !default;
$switch-track-dense-inset-width: 44px !default;
$switch-track-dense-top: calc(50% - 12px) !default;
$switch-track-dense-width: 32px !default;
$switch-track-height: 14px !default;
$switch-track-inset-border-radius: 14px !default;
$switch-track-inset-height: 28px !default;
$switch-track-inset-opacity: .32 !default;
$switch-track-inset-width: 48px !default;
$switch-track-opacity: .6 !default;
$switch-track-top: calc(50% - 7px) !default;
$switch-track-width: 36px !default;
$switch-track-x: 2px !default;
$switch-width: 38px !default;
