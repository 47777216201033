@import '../../styles/styles.sass';

$chip-group-no-color-opacity: .22 !default;
$chip-group-opacity: .32 !default;
$slider-horizontal-left: 8px !default;
$slider-horizontal-min-height: 32px !default;
$slider-horizontal-right: 8px !default;
$slider-label-margin-end: 12px !default;
$slider-label-margin-start: 12px !default;
$slider-state-track-background-opacity: 0.4 !default;
$slider-thumb-before-opacity: 0.3 !default;
$slider-thumb-border-radius: 50% !default;
$slider-thumb-focused-size-increase: 24px !default;
$slider-thumb-label-font-size: map-deep-get($headings, 'caption', 'size') !default;
$slider-thumb-label-height: 32px !default;
$slider-thumb-label-transition: .3s map-get($transition, 'fast-in-fast-out') !default;
$slider-thumb-label-width: 32px !default;
$slider-thumb-size: 12px !default;
$slider-tick-border-radius: 0 !default;
$slider-track-border-radius: 0 !default;
$slider-track-width: 2px !default;
$slider-transition: .3s map-get($transition, 'swing') !default;
$slider-vertical-margin-bottom: 12px !default;
$slider-vertical-margin-top: 12px !default;
$slider-vertical-min-height: 150px !default;
