@import '../tools/_elevation.sass';

$z: 24;
@while $z >= 0 {
  .v-application .elevation-#{$z} {
    @include elevation($z, $important: true);
  }

  $z: $z - 1;
}
