@import '../../styles/styles.sass';

$treeview-transition: .2s map-get($transition, 'linear-out-slow-in') !default;
$treeview-label-font-size: inherit !default;
$treeview-node-height: 48px !default;
$treeview-node-height-dense: 40px !default;
$treeview-node-shaped-margin: 8px !default;
$treeview-node-padding: 8px !default;
$treeview-node-margin: 6px !default;
$treeview-node-level-width: 24px !default;
