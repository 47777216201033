@import './_variables'
@import '../../styles/styles.sass'

.v-input--radio-group
  &__input
    border: none
    display: flex
    width: 100%

  &--column .v-input--radio-group__input > .v-label
    padding-bottom: $radio-group-padding

  &--row .v-input--radio-group__input > .v-label
    padding-right: $radio-group-padding

  &--row
    legend
      align-self: center
      display: inline-block

    .v-input--radio-group__input
      flex-direction: row
      flex-wrap: wrap

  &--column
    .v-radio:not(:last-child):not(:only-child)
      margin-bottom: $radio-group-padding

    .v-input--radio-group__input
      flex-direction: column
