@import './_variables.scss'

// Theme
+theme(v-bottom-navigation) using ($material)
  background-color: map-get($material, 'bottom-navigation')
  color: map-deep-get($material, 'text', 'primary')

  .v-btn:not(.v-btn--active)
    color: map-deep-get($material, 'text', 'secondary') !important


// Block
.v-item-group.v-bottom-navigation
  bottom: 0
  display: flex
  left: 0
  justify-content: center
  width: 100%
  +elevation(4)

  .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
    background-color: transparent

  .v-btn
    border-radius: 0
    box-shadow: none
    flex: 0 1 auto
    font-size: $bottom-nav-btn-font-size
    height: inherit
    max-width: $bottom-nav-btn-max-width
    min-width: $bottom-nav-btn-min-width
    position: relative
    text-transform: none

    &:after
      content: none

    .v-btn__content
      flex-direction: column-reverse
      height: inherit

      > *:not(.v-icon)
        line-height: 1.2

    &.v-btn--active
      color: inherit

      &:not(:hover):before
        opacity: 0

// Modifier
.v-item-group.v-bottom-navigation--absolute,
.v-item-group.v-bottom-navigation--fixed
  z-index: 4

.v-item-group.v-bottom-navigation--absolute
  position: absolute

.v-item-group.v-bottom-navigation--active
  transform: translate(0, 0)

.v-item-group.v-bottom-navigation--fixed
  position: fixed

.v-item-group.v-bottom-navigation--grow
  .v-btn
    width: 100%

.v-item-group.v-bottom-navigation--horizontal
  .v-btn > .v-btn__content
    flex-direction: row-reverse

    > .v-icon
      margin-bottom: 0
      margin-right: 16px

.v-item-group.v-bottom-navigation--shift
  .v-btn .v-btn__content > *:not(.v-icon)
    opacity: 0
    position: absolute
    top: $bottom-nav-shift-btn-top
    transform: scale(.9)
    transition: $primary-transition

  .v-btn--active .v-btn__content
    > .v-icon
      transform: translateY(-8px)

    > *:not(.v-icon)
      opacity: 1
      top: $bottom-nav-shift-btn-active-top
      transform: scale(1)
