@import './_variables.scss'

.v-autocomplete
  &.v-input > .v-input__control > .v-input__slot
    cursor: text

  input
    align-self: center

  &--is-selecting-index
    input
      opacity: 0

  // When a single select, does not have
  // selections padding
  &.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined)
    .v-select__slot > input
      margin-top: $autocomplete-enclosed-input-margin-top

    &.v-input--dense
      .v-select__slot > input
        margin-top: $autocomplete-dense-enclosed-input-margin-top

  &:not(.v-input--is-disabled).v-select.v-text-field
    input
      pointer-events: inherit

  &__content.v-menu__content
    border-radius: 0

    .v-card
      border-radius: 0
