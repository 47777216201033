@import './_variables.scss'

// Theme
+theme(v-label) using ($material)
  color: map-deep-get($material, 'text', 'secondary')

  &--is-disabled
    color: map-deep-get($material, 'text', 'disabled')

.v-label
  font-size: $label-font-size
  line-height: $label-line-height
  min-height: $label-min-height
  transition: .3s map-get($transition, 'swing')
