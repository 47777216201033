@import './_variables.scss'

.v-date-picker-years
  font-size: $date-picker-years-font-size
  font-weight: $date-picker-years-font-weight
  height: $date-picker-years-portrait-height
  list-style-type: none
  overflow: auto
  text-align: $date-picker-years-item-align

  &.v-date-picker-years
    padding: 0

  li
    cursor: pointer
    padding: $date-picker-years-item-padding
    transition: none

    &.active
      font-size: $date-picker-years-active-font-size
      font-weight: $date-picker-years-active-font-weight
      padding: $date-picker-years-active-padding

    &:hover
      background: $date-picker-years-item-hover-background

.v-picker--landscape
  .v-date-picker-years
    padding: 0
    height: $date-picker-years-landscape-height
