@font-face {
    font-family: "osm";
    src: url("./osm.eot?eba61992abfdcb834e4ce6ea62632dd7?#iefix") format("embedded-opentype"),
url("./osm.woff2?eba61992abfdcb834e4ce6ea62632dd7") format("woff2"),
url("./osm.woff?eba61992abfdcb834e4ce6ea62632dd7") format("woff"),
url("./osm.ttf?eba61992abfdcb834e4ce6ea62632dd7") format("truetype"),
url("./osm.svg?eba61992abfdcb834e4ce6ea62632dd7#osm") format("svg");
}

i[class^="osm-"]:before, i[class*=" osm-"]:before {
    font-family: osm !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.osm-arrow_down:before {
    content: "\f101";
}
.osm-bakery:before {
    content: "\f102";
}
.osm-bank:before {
    content: "\f103";
}
.osm-check_box:before {
    content: "\f104";
}
.osm-check_box_outline_blank:before {
    content: "\f105";
}
.osm-chevron_left:before {
    content: "\f106";
}
.osm-chevron_right:before {
    content: "\f107";
}
.osm-close:before {
    content: "\f108";
}
.osm-excrement_bags:before {
    content: "\f109";
}
.osm-filter_list:before {
    content: "\f10a";
}
.osm-food:before {
    content: "\f10b";
}
.osm-fuel:before {
    content: "\f10c";
}
.osm-funeral_directors:before {
    content: "\f10d";
}
.osm-info:before {
    content: "\f10e";
}
.osm-link:before {
    content: "\f10f";
}
.osm-mail:before {
    content: "\f110";
}
.osm-marketplace:before {
    content: "\f111";
}
.osm-motorcycle_parking:before {
    content: "\f112";
}
.osm-no_dogs:before {
    content: "\f113";
}
.osm-park:before {
    content: "\f114";
}
.osm-parking_entrance:before {
    content: "\f115";
}
.osm-pharmacy:before {
    content: "\f116";
}
.osm-phone:before {
    content: "\f117";
}
.osm-police:before {
    content: "\f118";
}
.osm-post_box:before {
    content: "\f119";
}
.osm-post_office:before {
    content: "\f11a";
}
.osm-public_bookcase:before {
    content: "\f11b";
}
.osm-recycling:before {
    content: "\f11c";
}
.osm-school:before {
    content: "\f11d";
}
.osm-shop:before {
    content: "\f11e";
}
.osm-time:before {
    content: "\f11f";
}
.osm-town_hall:before {
    content: "\f120";
}
.osm-twitter:before {
    content: "\f121";
}
.osm-velib:before {
    content: "\f122";
}
