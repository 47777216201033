@import '../../../styles/styles.sass'
@import '../_variables.scss'

+theme(v-calendar-events) using ($material)
  .v-event-timed
    border: $calendar-event-border-width solid !important

  .v-event-more
    background-color: map-deep-get($material, 'calendar', 'background-color')

    &.v-outside
      background-color: map-deep-get($material, 'calendar', 'outside-background-color')

.v-calendar
  .v-event
    position: relative
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-size: $calendar-event-font-size
    cursor: pointer
    margin-right: -$calendar-line-width
    z-index: 1
    border-radius: $calendar-event-border-radius

  .v-event-more
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-size: $calendar-event-font-size
    cursor: pointer
    font-weight: bold
    z-index: 1
    position: relative

  .v-event-timed-container
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin-right: $calendar-event-right-empty
    pointer-events: none

  .v-event-timed
    position: absolute
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    font-size: $calendar-event-font-size
    cursor: pointer
    border-radius: $calendar-event-border-radius
    pointer-events: all

  &.v-calendar-events
    .v-calendar-weekly__head-weekday
      margin-right: -$calendar-line-width
    .v-calendar-weekly__day
      overflow: visible
      margin-right: -$calendar-line-width
