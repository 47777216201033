@import './_variables.scss'

.v-date-picker-title
  display: flex
  justify-content: space-between
  flex-direction: column
  flex-wrap: wrap
  line-height: 1

  .v-picker__title__btn
    +ltr()
      text-align: left

    +rtl()
      text-align: right

  &__year
      align-items: center
      display: inline-flex
      font-size: $date-picker-title-year-font-size
      font-weight: $date-picker-title-year-font-weight
      margin-bottom: $date-picker-title-year-bottom-margin

  &__date
      font-size: $date-picker-title-date-font-size
      text-align: left
      font-weight: $date-picker-title-date-font-weight
      position: relative
      overflow: hidden
      padding-bottom: 8px
      margin-bottom: -8px

      > div
        position: relative

  &--disabled
    pointer-events: none
