@import './_variables.scss'

// Theme
+theme(v-overflow-btn) using ($material)
  &#{&} > .v-input__control > .v-input__slot
    border-color: map-get($material, 'dividers')

  &:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control > .v-input__slot:hover
      background: map-get($material, 'cards')

  &.v-overflow-btn--segmented
    .v-input__append-inner
      border-left: thin solid map-get($material, 'dividers')


.v-autocomplete__content.v-menu__content
  box-shadow: $overflow-menu-content-box-shadow

  .v-select-list
    border-radius: $overflow-menu-content-select-list-border-radius

.v-overflow-btn
  margin-top: $overflow-margin-top
  padding-top: 0

  &:not(.v-overflow-btn--editable) > .v-input__control > .v-input__slot
    cursor: pointer

  .v-input__slot
    border-width: $overflow-input-slot-border-width
    border-style: solid

    &:before
      display: none

  .v-select__slot
    height: $overflow-slot-height

  &.v-input--dense
    .v-select__slot
      height: $overflow-dense-slot-height

    input
      cursor: pointer

      +ltr()
        margin-left: $overflow-dense-input-margin-x

      +rtl()
        margin-right: $overflow-dense-input-margin-x

  .v-select__selection--comma:first-child
    +ltr()
      margin-left: $overflow-selection-comma-margin-x

    +rtl()
      margin-right: $overflow-selection-comma-margin-x

  .v-input__slot
    transition: .3s map-get($transition, 'swing')

    &::before,
    &::after
      display: none

  .v-label
    top: $overflow-label-top

    +ltr()
      margin-left: $overflow-label-margin-x

    +rtl()
      margin-right: $overflow-label-margin-x

  .v-input__append-inner
    align-items: center
    align-self: auto
    flex-shrink: 0
    height: $overflow-append-inner-height
    margin-top: 0
    padding: 0 4px
    width: $overflow-append-inner-width

  .v-input__append-outer,
  .v-input__prepend-outer
    margin-bottom: $overflow-append-prepend-margin-bottom
    margin-top: $overflow-append-prepend-margin-top

  .v-input__control::before
    // TODO: move to mixin
    height: 1px
    top: -1px
    content: ''
    left: 0
    position: absolute
    transition: $primary-transition
    width: 100%

  &.v-input--is-focused,
  &.v-select--is-menu-active
    .v-input__slot
      border-color: transparent !important
      box-shadow: $overflow-focused-active-slot-box-shadow

  &.v-input--is-focused
    .v-input__slot
      border-radius: $overflow-focused-active-border-radius

  &.v-select--is-menu-active
    .v-input__slot
      border-radius: $overflow-active-slot-border-radius

  .v-select__selections
    width: 0px

  &--segmented
    .v-input__slot
      border-width: $overflow-segmented-input-slot-border-width

    .v-select__selections
      flex-wrap: nowrap

      .v-btn
        border-radius: 0
        margin: 0
        height: $overflow-segmented-selections-btn-height
        width: 100%

        // push past the input's padding
        +ltr()
          margin-right: $overflow-segmented-selections-btn-margin-x

        +rtl()
          margin-left: $overflow-segmented-selections-btn-margin-x

        &__content
          justify-content: start

          &::before
            background-color: transparent

  &--editable
    .v-select__slot
      input
        cursor: text
        padding: $overflow-editable-select-slot-padding

    .v-input__append-inner,
    .v-input__append-inner *
      cursor: pointer
