@mixin treeview-shaped($size, $margin)
  .v-treeview-node__root,
  .v-treeview-node__root:before
    border-bottom-right-radius: #{$size * 0.5} !important
    border-top-right-radius: #{$size * 0.5} !important

  .v-treeview-node__root
    margin-top: $margin
    margin-bottom: $margin

@mixin treeview-rounded($size, $margin)
  .v-treeview-node__root,
  .v-treeview-node__root:before
    border-radius: #{$size * 0.5} !important

  .v-treeview-node__root
    margin-top: $margin
    margin-bottom: $margin
