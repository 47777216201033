@import '../styles.sass'

// Theme
+theme(v-input--selection-controls) using ($material)
  &.v-input--is-disabled:not(.v-input--indeterminate)
    .v-icon
      // needed for helper override
      color: map-deep-get($material, 'selection-controls', 'disabled') !important

.v-input--selection-controls
  margin-top: $input-top-spacing
  padding-top: 4px

  > .v-input__append-outer,
  > .v-input__prepend-outer
    margin-top: 0
    margin-bottom: 0

  &:not(.v-input--hide-details)
    > .v-input__slot
      margin-bottom: 12px

  .v-input__slot,
  .v-radio
    > .v-label
      align-items: center
      display: inline-flex
      flex: 1 1 auto
      // TODO: Resolve in v3, all selection controls need separate sass files
      height: auto

  &__input
    color: inherit
    display: inline-flex
    flex: 0 0 auto
    height: 24px
    position: relative
    transition: $primary-transition
    transition-property: transform
    width: 24px
    user-select: none

    .v-icon
      width: 100%

    +ltr()
      margin-right: 8px

    +rtl()
      margin-left: 8px

    input[role="checkbox"],
    input[role="radio"],
    input[role="switch"]
      position: absolute
      opacity: 0
      width: 100%
      height: 100%
      cursor: pointer
      user-select: none

    // Sibling selector to avoid targeting v-radio-group's label
    & + .v-label
      cursor: pointer
      user-select: none

  &__ripple
    border-radius: 50%
    cursor: pointer
    height: 34px
    position: absolute
    transition: inherit
    width: 34px
    left: -12px
    top: calc(50% - 24px)
    margin: 7px

    &:before
      border-radius: inherit
      bottom: 0
      content: ''
      position: absolute
      opacity: .2
      left: 0
      right: 0
      top: 0
      transform-origin: center center
      transform: scale(0.2)
      transition: inherit

    > .v-ripple__container
      transform: scale(1.2)

  &.v-input--dense
    .v-input--selection-controls__ripple
      width: 28px
      height: 28px
      left: -9px

    &:not(.v-input--switch)
      .v-input--selection-controls__ripple
        top: calc(50% - 21px)

  &.v-input
    flex: 0 1 auto

  &.v-input--is-focused,
  .v-radio--is-focused
    .v-input--selection-controls__ripple:before
      background: currentColor
      transform: scale(1.2)

  .v-input--selection-controls__input:hover
    .v-input--selection-controls__ripple:before
      background: currentColor
      transform: scale(1.2)
      transition: none
