@import '../../styles/styles.sass';

$textarea-box-enclosed-prefix-margin-top: 24px !default;
$textarea-box-enclosed-single-outlined-label-top: 18px !default;
$textarea-box-enclosed-single-outlined-margin-top: 10px !default;
$textarea-dense-box-enclosed-single-outlined-margin-top: 6px !default;
$textarea-dense-append-prepend-margin-top: 8px !default;
$textarea-enclosed-text-slot-margin: -12px !default;
$textarea-enclosed-text-slot-padding: 12px !default;
$textarea-line-height: 1.75rem !default;
$textarea-min-height: 32px !default;
$textarea-padding: 0 !default;
$textarea-prefix-padding-top: 2px !default;
$textarea-solo-append-padding: 12px !default;
$textarea-solo-append-prepend-margin-top: 12px !default;
