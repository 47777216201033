@import './_variables.scss'
@import './_mixins.sass'

// Theme
.v-list
  &.primary,
  &.secondary,
  &.accent,
  &.success,
  &.error,
  &.warning,
  &.info
    > .v-list-item
      color: map-deep-get($material-dark, 'text', 'primary')

+theme(v-list) using ($material)
  background: map-get($material, 'cards')
  color: map-deep-get($material, 'text','primary')

  .v-list--disabled
    color: map-deep-get($material, 'text', 'disabled')

  .v-list-group--active:before,
  .v-list-group--active:after
    background: map-get($material, 'dividers')

// Block
.v-list
  border-radius: $list-border-radius
  display: block
  padding: $list-padding
  position: static
  +elevationTransition()

// Modifier
.v-list--disabled
  pointer-events: none

.v-list--flat
  .v-list-item:before
    display: none

.v-list--dense
  .v-subheader
    font-size: $list-dense-subheader-font-size
    height: $list-dense-subheader-height
    padding: $list-dense-subheader-padding

.v-list--nav,
.v-list--rounded
  .v-list-item:not(:last-child):not(:only-child)
    margin-bottom: $list-nav-rounded-item-margin-bottom

  &.v-list--dense .v-list-item,
  .v-list-item--dense
    &:not(:last-child):not(:only-child)
      margin-bottom: $list-nav-rounded-dense-item-margin-bottom

.v-list--nav
  padding-left: $list-nav-padding-left
  padding-right: $list-nav-padding-right

  .v-list-item
    padding: $list-nav-item-padding

  .v-list-item,
  .v-list-item:before
    border-radius: $list-border-radius

.v-list--shaped
  +list-shaped($list-item-min-height)

  &.v-list--two-line
    +list-shaped($list-item-two-line-min-height)

  &.v-list--three-line
    +list-shaped($list-item-three-line-min-height)

  +ltr()
    padding-right: $list-shaped-padding

  +rtl()
    padding-left: $list-shaped-padding

.v-list--rounded
  padding: 8px
  +list-rounded($list-item-min-height)

  &.v-list--two-line
    +list-rounded($list-item-two-line-min-height)

  &.v-list--three-line
    +list-rounded($list-item-three-line-min-height)

.v-list--subheader
  padding-top: $list-subheader-padding-top
