@import './_variables.scss'

// Theme
+theme(v-radio) using ($material)
  &--is-disabled
    label
      color: map-deep-get($material, 'text', 'disabled')

    .v-icon
      // needed for helper override
      color: map-deep-get($material, 'selection-controls', 'disabled') !important

.v-radio
  align-items: center
  display: flex
  height: auto
  outline: none

  &--is-disabled
    pointer-events: none

.v-input--radio-group.v-input--radio-group--row
  .v-radio
    margin-right: $radio-margin-right
