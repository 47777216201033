@import './_variables.scss'

// Theme
+theme(v-footer) using ($material)
  background-color: map-get($material, 'app-bar')
  color: map-deep-get($material, 'text', 'primary')

// Block
.v-footer
  align-items: center
  display: flex
  flex: 0 1 auto !important // Don't let devs break their code
  flex-wrap: wrap
  padding: $footer-padding
  position: relative
  transition-duration: $footer-transition-duration
  transition-property: $footer-transition-property
  transition-timing-function: $footer-transition-timing-function
  +bootable()

// Modifier
.v-footer--absolute,
.v-footer--fixed
  z-index: 3

.v-footer--absolute
  position: absolute
  width: 100%

.v-footer--fixed
  position: fixed

.v-footer--padless
  padding: $footer-padless-padding
