@import '../../styles/styles.sass';

$date-picker-years-font-size: 16px !default;
$date-picker-years-font-weight: 400 !default;
$date-picker-years-portrait-height: 290px !default;
$date-picker-years-landscape-height: 290px !default;
$date-picker-years-item-padding: 8px 0 !default;
$date-picker-years-active-font-size: 26px !default;
$date-picker-years-active-font-weight: 500 !default;
$date-picker-years-active-padding: 10px 0 !default;
$date-picker-years-item-hover-background: rgba(0, 0, 0, 0.12) !default;
$date-picker-years-item-align: center !default;

$date-picker-title-year-font-size: 14px !default;
$date-picker-title-year-font-weight: 500 !default;
$date-picker-title-year-bottom-margin: 8px !default;
$date-picker-title-date-font-size: 34px !default;
$date-picker-title-date-font-weight: 500 !default;

$date-picker-header-padding: 4px 16px !default;
$date-picker-header-value-transition: $primary-transition !default;
$date-picker-header-button-font-weight: bold !default;
$date-picker-header-button-padding: 0.5rem !default;
$date-picker-header-button-transition: $primary-transition !default;

$date-picker-table-padding: 0 12px !default;
$date-picker-table-height: 242px !default;
$date-picker-table-font-size: 12px !default;
$date-picker-table-date-button-width: 32px !default;
$date-picker-table-date-button-height: 32px !default;
$date-picker-table-active-date-color: map-get($shades, 'white') !default;
$date-picker-table-month-height: 56px !default;
$date-picker-table-month-min-width: 40px !default;
$date-picker-table-month-max-width: 140px !default;
$date-picker-table-date-padding: 8px 0 !default;
$date-picker-table-date-font-weight: 600 !default;
$date-picker-table-date-width: 45px !default;
$date-picker-event-size: 8px !default;
$date-picker-event-margin: 0 1px !default;
$date-picker-event-border-radius: 50% !default;
$date-picker-event-month-bottom: 6px !default;
$date-picker-event-date-bottom: 8px !default;
