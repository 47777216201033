@import './_variables.scss'

+theme(v-date-picker-header) using ($material)
  .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
    button:not(:hover):not(:focus)
      color: map-deep-get($material, 'text', 'primary')

  .v-date-picker-header__value--disabled
    button
      color: map-deep-get($material, 'text', 'disabled')

.v-date-picker-header
  padding: $date-picker-header-padding

  align-items: center
  display: flex
  justify-content: space-between
  position: relative

  .v-btn
    margin: 0
    z-index: auto

  .v-icon
    cursor: pointer
    user-select: none

.v-date-picker-header__value
  flex: 1
  text-align: center
  position: relative
  overflow: hidden

  div
    transition: $date-picker-header-value-transition
    width: 100%

  button
    cursor: pointer
    font-weight: $date-picker-header-button-font-weight
    outline: none
    padding: $date-picker-header-button-padding
    transition: $date-picker-header-button-transition

.v-date-picker-header--disabled
  pointer-events: none
