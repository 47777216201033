@import '../../styles/styles.sass';

$btn-toggle-border-radius: $border-radius-root !default;
$btn-toggle-shaped-border-radius: 24px !default;
$btn-toggle-btn-height: 48px !default;
$btn-toggle-btn-padding: 0 12px !default;
$btn-toggle-btn-width: 48px !default;
$btn-toggle-btn-opacity: 0.8 !default;
$btn-toggle-round-border-radius: 24px !default;
$btn-toggle-dense-btn-padding: 0 8px !default;
$btn-toggle-group-btn-margin: 4px !default;
