@import '../../styles/styles.sass';

$text-field-border-radius: $border-radius-root !default;
$text-field-line-height: 20px !default;
$text-field-padding: 8px 0 8px !default;
$text-field-dense-padding: 4px 0 2px !default;
$text-field-append-prepend-margin: 4px !default;
$text-field-filled-full-width-outlined-slot-min-height: 56px !default;
$text-field-filled-full-width-outlined-dense-slot-min-height: 52px !default;
$text-field-filled-full-width-outlined-single-line-slot-min-height: 40px !default;
$text-field-filled-border-radius: $text-field-border-radius $text-field-border-radius 0 0 !default;
$text-field-counter-margin: 8px !default;
$text-field-label-top: 6px !default;
$text-field-label-active-transform: translateY(-18px) scale(.75) !default;
$text-field-details-min-height: 14px !default;
$text-field-filled-full-width-label-top: 18px !default;
$text-field-filled-full-width-label-active-transform: translateY(-6px) scale(.75) !default;
$text-field-dense-label-top: 17px !default;
$text-field-dense-label-active-transform: translateY(-10px) scale(.75) !default;
$text-field-single-line-label-top: 11px !default;
$text-field-filled-margin-top: 22px !default;
$text-field-enclosed-prepend-append-margin-top: 17px !default;
$text-field-dense-prepend-append-margin-top: 14px !default;
$text-field-single-line-prepend-append-margin-top: 9px !default;
$text-field-outlined-prepend-append-margin-top: 7px !default;
$text-field-enclosed-details-padding: 0 12px !default;
$text-field-details-margin-bottom: 8px !default;
$text-field-outlined-margin-bottom: 16px !default;
$text-field-outlined-label-position-x: 18px !default;
$text-field-outlined-label-position-y: -24px !default;
$text-field-outlined-dense-label-position-x: 10px !default;
$text-field-outlined-dense-label-position-y: -16px !default;
$text-field-prefix-max-height: 32px !default;
$text-field-outlined-append-prepend-outer-margin-top: 18px !default;
$text-field-outlined-fieldset-top: -5px !default;
$text-field-outlined-fieldset-border-width: 1px !default;
$text-field-outlined-fieldset-padding: 8px !default;
$text-field-outlined-legend-line-height: 11px !default;
$text-field-rounded-legend-margin: 12px !default;
$text-field-rounded-border-radius: 28px !default;
$text-field-shaped-border-radius: 16px 16px 0 0 !default;
$text-field-outlined-fieldset-border: 2px solid currentColor !default;
$text-field-outlined-rounded-slot-padding: 0 24px !default;
$text-field-solo-label-top: calc(50% - 10px) !default;
$text-field-solo-control-min-height: 48px !default;
$text-field-solo-dense-control-min-height: 38px !default;
$text-field-solo-outer-margin-top: 12px !default;
$text-field-solo-dense-outer-margin-top: 7px !default;
