@import '../../styles/styles.sass'

.container
  &.grow-shrink-0
    flex-grow: 0
    flex-shrink: 0

  &.fill-height
    align-items: center
    display: flex
    flex-wrap: wrap

    > .row
      flex: 1 1 100%
      max-width: 100%

    > .layout
      height: 100%
      flex: 1 1 auto

      &.grow-shrink-0
        flex-grow: 0
        flex-shrink: 0

  &.grid-list
    @each $size, $gutter in $grid-gutters
      &-#{$size}
        .layout
          .flex
            padding: $gutter / 2

        .layout:only-child
          margin: -$gutter / 2

        .layout:not(:only-child)
          margin: auto (-$gutter / 2)

        *:not(:only-child)
          .layout:first-child
            margin-top: -$gutter / 2

          .layout:last-child
            margin-bottom: -$gutter / 2

.layout
  display: flex
  flex: 1 1 auto
  flex-wrap: nowrap
  // https://github.com/vuetifyjs/vuetify/issues/3873
  min-width: 0

  &.reverse
    flex-direction: row-reverse

  &.column
    flex-direction: column

    &.reverse
      flex-direction: column-reverse

    > .flex
      max-width: 100%

  &.wrap
    flex-wrap: wrap

  &.grow-shrink-0
    flex-grow: 0
    flex-shrink: 0

@each $size, $width in $grid-breakpoints
  @media all and (min-width: #{$width})
    $n: $grid-columns
    @while $n > 0
      .flex.#{$size}#{$n}
        flex-basis: $n / $grid-columns * 100%
        flex-grow: 0
        max-width: $n / $grid-columns * 100%

      .flex.order-#{$size}#{$n}
        order: $n

      $n: $n - 1

    $m: $grid-columns
    @while $m >= 0
      .flex.offset-#{$size}#{$m}
        // Offsets can only ever work in row layouts
        margin-left: $m / $grid-columns * 100%

      $m: $m - 1

.flex,
.child-flex > *
  flex: 1 1 auto
  max-width: 100%

  &.grow-shrink-0
    flex-grow: 0
    flex-shrink: 0

.spacer
  flex-grow: 1 !important

.grow
  flex-grow: 1 !important
  flex-shrink: 0 !important

.shrink
  flex-grow: 0 !important
  flex-shrink: 1 !important

.fill-height
  height: 100%
