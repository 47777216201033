// Imports
@import './_variables.scss'

// Theme
+theme(v-data-table) using ($material)
  .v-data-table-header
    th.sortable
      .v-data-table-header__icon
        color: map-deep-get($material, 'text', 'disabled')

      &:hover,
      &.active
        color: map-deep-get($material, 'text', 'primary')

      &.active
        .v-data-table-header__icon
          color: map-deep-get($material, 'text', 'primary')

    &__sort-badge
      background-color: map-get($material, 'dividers')
      color: map-deep-get($material, 'text', 'primary')

// Block
.v-data-table-header
  th
    &.sortable
      pointer-events: auto
      cursor: pointer
      outline: 0

    &.active, &:hover
      .v-data-table-header__icon
        transform: none
        opacity: 1

    &.desc
      .v-data-table-header__icon
        transform: rotate(-180deg)

.v-data-table-header__icon
  display: inline-block
  opacity: 0
  transition: .3s map-get($transition, 'swing')

.v-data-table-header__sort-badge
  display: inline-flex
  justify-content: center
  align-items: center
  border: 0px
  border-radius: 50%
  min-width: $data-table-header-sort-badge-min-height
  min-height: $data-table-header-sort-badge-min-width
  height: $data-table-header-sort-badge-height
  width: $data-table-header-sort-badge-width

// Mobile
.v-data-table-header-mobile
  th
    height: initial

.v-data-table-header-mobile__wrapper
  display: flex

  .v-select
    margin-bottom: $data-table-header-mobile-select-margin-bottom

    .v-chip
      height: $data-table-header-mobile-select-chip-height

    .v-chip__close.desc
      .v-icon
        transform: rotate(-180deg)

.v-data-table-header-mobile__select
  min-width: $data-table-header-mobile-select-max-width
  display: flex
  align-items: center
  justify-content: center
