.v-application
  .display-4
    font-size: map-deep-get($headings, 'h1', 'size') !important
    font-weight: map-deep-get($headings, 'h1', 'weight')
    line-height: map-deep-get($headings, 'h1', 'line-height')
    letter-spacing: map-deep-get($headings, 'h1', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h1', 'font-family') !important

  .display-3
    font-size: map-deep-get($headings, 'h2', 'size') !important
    font-weight: map-deep-get($headings, 'h2', 'weight')
    line-height: map-deep-get($headings, 'h2', 'line-height')
    letter-spacing: map-deep-get($headings, 'h2', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h2', 'font-family') !important

  .display-2
    font-size: map-deep-get($headings, 'h3', 'size') !important
    font-weight: map-deep-get($headings, 'h3', 'weight')
    line-height: map-deep-get($headings, 'h3', 'line-height')
    letter-spacing: map-deep-get($headings, 'h3', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h3', 'font-family') !important

  .display-1
    font-size: map-deep-get($headings, 'h4', 'size') !important
    font-weight: map-deep-get($headings, 'h4', 'weight')
    line-height: map-deep-get($headings, 'h4', 'line-height')
    letter-spacing: map-deep-get($headings, 'h4', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h4', 'font-family') !important

  .headline
    font-size: map-deep-get($headings, 'h5', 'size') !important
    font-weight: map-deep-get($headings, 'h5', 'weight')
    line-height: map-deep-get($headings, 'h5', 'line-height')
    letter-spacing: map-deep-get($headings, 'h5', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h5', 'font-family') !important

  .title
    font-size: map-deep-get($headings, 'h6', 'size') !important
    font-weight: map-deep-get($headings, 'h6', 'weight')
    line-height: map-deep-get($headings, 'h6', 'line-height')
    letter-spacing: map-deep-get($headings, 'h6', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h6', 'font-family') !important

  .subtitle-2
    font-size: map-deep-get($headings, 'subtitle-2', 'size') !important
    font-weight: map-deep-get($headings, 'subtitle-2', 'weight')
    letter-spacing: map-deep-get($headings, 'subtitle-2', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'subtitle-2', 'line-height')
    font-family: map-deep-get($headings, 'subtitle-2', 'font-family') !important

  .subtitle-1
    font-size: map-deep-get($headings, 'subtitle-1', 'size') !important
    font-weight: map-deep-get($headings, 'subtitle-1', 'weight')
    letter-spacing: map-deep-get($headings, 'subtitle-1', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'subtitle-1', 'line-height')
    font-family: map-deep-get($headings, 'subtitle-1', 'font-family') !important

  .body-2
    font-size: map-deep-get($headings, 'body-2', 'size') !important
    font-weight: map-deep-get($headings, 'body-2', 'weight')
    letter-spacing: map-deep-get($headings, 'body-2', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'body-2', 'line-height')
    font-family: map-deep-get($headings, 'body-2', 'font-family') !important

  .body-1
    font-size: map-deep-get($headings, 'body-1', 'size') !important
    font-weight: map-deep-get($headings, 'body-1', 'weight')
    letter-spacing: map-deep-get($headings, 'body-1', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'body-1', 'line-height')
    font-family: map-deep-get($headings, 'body-1', 'font-family') !important

  .caption
    font-size: map-deep-get($headings, 'caption', 'size') !important
    font-weight: map-deep-get($headings, 'caption', 'weight')
    letter-spacing: map-deep-get($headings, 'caption', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'caption', 'line-height')
    font-family: map-deep-get($headings, 'caption', 'font-family') !important

  .overline
    font-size: map-deep-get($headings, 'overline', 'size') !important
    font-weight: map-deep-get($headings, 'overline', 'weight')
    letter-spacing: map-deep-get($headings, 'overline', 'letter-spacing') !important
    line-height: map-deep-get($headings, 'overline', 'line-height')
    text-transform: uppercase
    font-family: map-deep-get($headings, 'overline', 'font-family') !important

  p
    margin-bottom: $spacer * 4
