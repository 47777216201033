@import './_variables.scss'

// Theme
+theme(v-time-picker-clock) using ($material)
  background: map-deep-get($material, 'picker', 'clock')

  .v-time-picker-clock__item--disabled
    color: map-deep-get($material, 'buttons', 'disabled')

    &.v-time-picker-clock__item--active
      color: map-deep-get($material-dark, 'buttons', 'disabled')

  &--indeterminate
    .v-time-picker-clock__hand
      background-color: map-deep-get($material, 'picker', 'indeterminateTime')

    &:after
      color: map-deep-get($material, 'picker', 'indeterminateTime')

    .v-time-picker-clock__item--active
      background-color: map-deep-get($material, 'picker', 'indeterminateTime')

.v-time-picker-clock
  border-radius: 100%
  position: relative
  transition: $primary-transition
  user-select: none
  width: 100%
  padding-top: 100%
  flex: 1 0 auto

  &__container
    display: flex
    flex-direction: column
    justify-content: center
    padding: $time-picker-clock-padding

  &__ampm
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-end
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    margin: 0
    padding: $time-picker-ampm-padding

  &__hand
    height: $time-picker-clock-hand-height
    width: $time-picker-clock-hand-width
    bottom: 50%
    left: $time-picker-clock-hand-left
    transform-origin: center bottom
    position: absolute
    will-change: transform
    z-index: 1

    &:before
      background: transparent
      border-width: $time-picker-clock-end-border-width
      border-style: $time-picker-clock-end-border-style
      border-color: $time-picker-clock-end-border-color
      border-radius: 100%
      width: $time-picker-clock-end-size
      height: $time-picker-clock-end-size
      content: ''
      position: absolute
      top: $time-picker-clock-end-top
      left: 50%
      transform: translate(-50%, -50%)

    &:after
      content: ''
      position: absolute
      height: $time-picker-clock-center-size
      width: $time-picker-clock-center-size
      top: 100%
      left: 50%
      border-radius: 100%
      border-style: solid
      border-color: inherit
      background-color: inherit
      transform: translate(-50%, -50%)

    &--inner:after
      height: $time-picker-clock-inner-hand-height

.v-picker--full-width
  .v-time-picker-clock__container
    max-width: $time-picker-clock-max-width

.v-time-picker-clock__inner
  position: absolute
  bottom: $time-picker-clock-inner-offset
  left: $time-picker-clock-inner-offset
  right: $time-picker-clock-inner-offset
  top: $time-picker-clock-inner-offset

.v-time-picker-clock__item
  align-items: center
  border-radius: 100%
  cursor: default
  display: flex
  font-size: $time-picker-number-font-size
  justify-content: center
  height: $time-picker-indicator-size
  position: absolute
  text-align: center
  width: $time-picker-indicator-size
  user-select: none
  transform: translate(-50%, -50%)

  > span
    z-index: 1

  &:before, &:after
    content: ''
    border-radius: 100%
    position: absolute
    top: 50%
    left: 50%
    height: 14px
    width: 14px
    transform: translate(-50%, -50%)

  &:after, &:before
    height: $time-picker-indicator-size
    width: $time-picker-indicator-size

  &--active
    color: map-get($shades, 'white')
    cursor: default
    z-index: 2

  &--disabled
    pointer-events: none

.v-picker--landscape
  .v-time-picker-clock
    &__container
      flex-direction: row

    &__ampm
      flex-direction: column
