@import './_variables.scss'

// Block
.v-dialog
  border-radius: $dialog-border-radius
  margin: $dialog-margin
  overflow-y: auto
  pointer-events: auto
  transition: .3s map-get($transition, 'fast-in-fast-out')
  width: 100%
  z-index: inherit
  +elevation($dialog-elevation)

  &:not(.v-dialog--fullscreen)
    max-height: $dialog-max-height

  > *
    width: 100%

  > .v-card
    > .v-card__title
      font-size: $dialog-card-title-font-size
      font-weight: $dialog-card-title-font-weight
      letter-spacing: $dialog-card-title-letter-spacing
      padding: $dialog-card-title-padding

    > .v-card__text
      padding: $dialog-card-text-padding

    > .v-card__subtitle
      padding: $dialog-card-subtitle-padding

// Element
.v-dialog__content
  align-items: center
  display: flex
  height: 100%
  justify-content: center
  left: 0
  pointer-events: none
  position: fixed
  top: 0
  transition: .2s map-get($transition, 'fast-in-fast-out'), z-index 1ms
  width: 100%
  z-index: 6
  outline: none

.v-dialog__container
  display: none

  &--attached
    display: inline

// Modifier
.v-dialog--animated
  animation-duration: .15s
  animation-name: animate-dialog
  animation-timing-function: map-get($transition, 'fast-in-fast-out')

.v-dialog--fullscreen
  border-radius: 0
  margin: 0
  height: 100%
  position: fixed
  overflow-y: auto
  top: 0
  left: 0

  > .v-card
    min-height: 100%
    min-width: 100%
    margin: 0 !important
    padding: 0 !important

.v-dialog--scrollable,
.v-dialog--scrollable > form
  display: flex

  > .v-card
    display: flex
    flex: 1 1 100%
    flex-direction: column
    max-height: 100%
    max-width: 100%

    > .v-card__title,
    > .v-card__actions
      flex: 0 0 auto

    > .v-card__text
      backface-visibility: hidden
      flex: 1 1 auto
      overflow-y: auto

@keyframes animate-dialog
  0%
    transform: scale(1)

  50%
    transform: scale(1.03)

  100%
    transform: scale(1)
