@import './_variables.scss'

.v-avatar
  align-items: center
  border-radius: $avatar-border-radius
  display: inline-flex
  justify-content: center
  line-height: normal
  position: relative
  text-align: center
  vertical-align: middle
  overflow: hidden

  img,
  svg,
  .v-icon,
  .v-image,
  .v-responsive__content
    border-radius: inherit
    display: inline-flex
    height: inherit
    width: inherit

  &--tile
    border-radius: 0
