@import '../../styles/styles.sass';

$tab-disabled-opacity: .5 !default;
$tab-font-size: map-deep-get($headings, 'subtitle-2', 'size') !default;
$tab-font-weight: map-deep-get($headings, 'subtitle-2', 'weight') !default;
$tab-line-height: normal !default;
$tabs-bar-background-color: 'cards' !default;
$tabs-bar-height: 48px !default;
$tabs-icons-and-text-bar-height: 72px !default;
$tabs-icons-and-text-first-tab-margin-bottom: 6px !default;
$tabs-item-align-with-title-margin: 42px !default;
$tabs-item-focus-opacity: 0.20 !default;
$tabs-item-hover-opacity: 0.16 !default;
$tabs-item-letter-spacing: .0892857143em !default;
$tabs-item-max-width: 360px !default;
$tabs-item-min-width: 90px !default;
$tabs-item-padding: 0 16px !default;
$tabs-item-vertical-height: $tabs-bar-height !default;
$tabs-item-vertical-icons-and-text-height: $tabs-icons-and-text-bar-height !default;
