@import './_variables.scss'

.v-responsive
  position: relative
  overflow: hidden
  flex: 1 0 auto
  max-width: 100%
  display: flex

  &__content
    flex: 1 0 0px
    max-width: 100%

  &__sizer ~ &__content
    +ltr()
      margin-left: -100%

    +rtl()
      margin-right: -100%

  &__sizer
    transition: padding-bottom 0.2s map-get($transition, 'swing')
    flex: 1 0 0px
