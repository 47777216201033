@mixin list-shaped($size)
  .v-list-item
    &,
    &::before,
    > .v-ripple__container
      +ltr()
        border-bottom-right-radius: #{$size * 0.6666666666666667} !important
        border-top-right-radius: #{$size * 0.6666666666666667} !important
      +rtl()
        border-bottom-left-radius: #{$size * 0.6666666666666667} !important
        border-top-left-radius: #{$size * 0.6666666666666667} !important

@mixin list-rounded($size)
  .v-list-item
    &,
    &::before,
    > .v-ripple__container
      border-radius: #{$size * 0.6666666666666667} !important
