// Imports
@import './_variables.scss'

// Theme
+theme(v-icon) using ($material)
  color: map-deep-get($material, 'icons', 'active')

  &:focus::after
    opacity: map-deep-get($material, 'states', 'focus')

  &.v-icon.v-icon--disabled
    color: map-deep-get($material, 'icons', 'inactive') !important

// Increased specificity to overwrite
// iconfont css interference
.v-icon.v-icon
  align-items: center
  display: inline-flex
  font-feature-settings: 'liga'
  font-size: $icon-size
  justify-content: center
  letter-spacing: normal
  line-height: 1
  position: relative
  text-indent: 0
  transition: $primary-transition, visibility 0s
  vertical-align: middle
  user-select: none

  &::after
    background-color: currentColor
    border-radius: 50%
    content: ''
    display: inline-block
    height: 100%
    opacity: 0
    pointer-events: none
    position: absolute
    transform: scale(1.3)
    width: 100%
    transition: opacity 0.2s map-get($transition, 'ease-in-out')

  &--dense
    font-size: $icon-size-dense

.v-icon
  &--right
    margin-left: map-get($grid-gutters, 'md')
  &--left
    margin-right: map-get($grid-gutters, 'md')

  &.v-icon.v-icon--link
    cursor: pointer
    outline: none

  &--disabled
    pointer-events: none

  &--is-component
    height: $icon-size
    width: $icon-size

  &--svg
    height: $icon-size
    width: $icon-size
    fill: currentColor

  &--dense
    &--is-component
      height: $icon-size-dense
