@import '../../styles/styles.sass';

$color-picker-border-radius: $border-radius-root !default;
$color-picker-checkerboard: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
$color-picker-swatch-color-width: 45px !default;
$color-picker-swatch-color-height: 18px !default;
$color-picker-swatch-color-margin: 2px 4px !default;
$color-picker-swatch-color-border-radius: 2px !default;
$color-picker-swatch-margin-bottom: 10px !default;
$color-picker-swatches-border-radius: 8px !default;
$color-picker-canvas-dot-size: 15px !default;
$color-picker-canvas-dot-box-shadow: 0px 0px 0px 1.5px rgba(255, 255, 255, 1), inset 0px 0px 1px 1.5px rgba(0, 0, 0, 0.3) !default;
$color-picker-canvas-dot-disabled-box-shadow: 0px 0px 0px 1.5px rgba(255, 255, 255, 0.7), inset 0px 0px 1px 1.5px rgba(0, 0, 0, 0.3) !default;
$color-picker-controls-padding: 16px !default;
$color-picker-edit-margin-top: 24px !default;
$color-picker-input-height: 28px !default;
$color-picker-input-font-size: 0.75rem !default;
$color-picker-input-margin: 8px !default;
$color-picker-input-margin-bottom: 8px !default;
$color-picker-slider-height: 10px !default;
$color-picker-slider-border-radius: $color-picker-slider-height / 2 !default;
$color-picker-preview-dot-size: 30px !default;
$color-picker-preview-dot-margin: 24px !default;
$color-picker-hue-margin-bottom: 24px !default;
