@import './_variables.scss'

// Theme
+theme(v-calendar-daily) using ($material)
  background-color: map-deep-get($material, 'calendar', 'background-color')
  border-left: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid
  border-top: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

  .v-calendar-daily__intervals-head
    border-right: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

    &::after
      background: map-deep-get($material, 'calendar', 'line-color')
      background: linear-gradient(90deg, transparent, map-deep-get($material, 'calendar', 'line-color'))
  
  .v-calendar-daily_head-day
    border-right: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid
    border-bottom: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid
    color: map-deep-get($material, 'calendar', 'text-color')

    &.v-past
      .v-calendar-daily_head-weekday,
      .v-calendar-daily_head-day-label
        color: map-deep-get($material, 'calendar', 'past-color')

  .v-calendar-daily__intervals-body
    border-right: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

    .v-calendar-daily__interval-text
      color: map-deep-get($material, 'calendar', 'interval-color')

  .v-calendar-daily__day
    border-right: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid
    border-bottom: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

  .v-calendar-daily__day-interval
    border-top: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

    &:first-child
      border-top: none !important

  .v-calendar-daily__interval::after
    border-top: map-deep-get($material, 'calendar', 'line-color') $calendar-line-width solid

.v-calendar-daily
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%

.v-calendar-daily__head
  flex: none
  display: flex

.v-calendar-daily__intervals-head
  flex: none
  position: relative

  &::after
    position: absolute
    bottom: 0px
    height: $calendar-line-width
    left: 0
    right: 0
    content: ''

.v-calendar-daily_head-day
  flex: 1 1 auto
  width: 0
  position: relative

.v-calendar-daily_head-weekday
  user-select: none
  padding: $calendar-daily-weekday-padding
  font-size: $calendar-daily-weekday-font-size
  text-align: center
  text-transform: uppercase

.v-calendar-daily_head-day-label
  user-select: none
  padding: $calendar-daily-day-padding
  cursor: pointer
  text-align: center

.v-calendar-daily__body
  flex: 1 1 60%
  overflow: hidden
  display: flex
  position: relative
  flex-direction: column

.v-calendar-daily__scroll-area
  overflow-y: scroll
  flex: 1 1 auto
  display: flex
  align-items: flex-start

.v-calendar-daily__pane
  width: 100%
  overflow-y: hidden
  flex: none
  display: flex
  align-items: flex-start

.v-calendar-daily__day-container
  display: flex
  flex: 1
  width: 100%
  height: 100%

.v-calendar-daily__intervals-body
  flex: none
  user-select: none

.v-calendar-daily__interval
  text-align: $calendar-daily-interval-gutter-align
  padding-right: $calendar-daily-interval-gutter-line-width
  border-bottom: none
  position: relative

  &::after
    width: $calendar-daily-interval-gutter-line-width
    position: absolute
    height: $calendar-line-width
    display: block
    content: ''
    right: 0
    bottom: -$calendar-line-width

.v-calendar-daily__interval-text
  display: block
  position: relative
  top: $calendar-daily-interval-gutter-top
  font-size: $calendar-daily-interval-gutter-font-size
  padding-right: $calendar-daily-interval-gutter-width

.v-calendar-daily__day
  flex: 1
  width: 0
  position: relative
