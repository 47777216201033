@import './_variables'
@import '../../styles/styles.sass'

+theme(v-file-input) using ($material)
  .v-file-input__text
    color: map-deep-get($material, 'text', 'primary')

    &--placeholder
      color: map-deep-get($material, 'text', 'secondary')

  &.v-input--is-disabled
    .v-file-input__text
      color: map-deep-get($material, 'text', 'disabled')

      .v-file-input__text--placeholder
        color: map-deep-get($material, 'text', 'disabled')

// Block
.v-file-input
  input[type="file"]
    left: 0
    opacity: 0
    position: absolute
    max-width: 0
    width: 0

// Element
.v-file-input .v-file-input__text
  align-items: center
  align-self: stretch
  display: flex
  flex-wrap: wrap
  width: 100%

  &.v-file-input__text--chips
    flex-wrap: wrap

  .v-chip
    margin: $file-input-chip-margin

.v-file-input .v-text-field__slot
  min-height: $file-input-slot-min-height

.v-file-input.v-text-field--filled:not(.v-text-field--single-line)
  .v-file-input__text
    padding-top: $file-input-filled-padding-top

.v-file-input.v-text-field--outlined
  .v-text-field__slot
    padding: $file-input-outlined-padding

  &.v-input--dense
    .v-text-field__slot
      padding: $file-input-outlined-dense-padding
