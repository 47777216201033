@import './_variables.scss'

+theme(v-subheader) using ($material)
  color: map-deep-get($material, 'text', 'secondary')

.v-subheader
  align-items: center
  display: flex
  height: $subheader-item-single-height
  font-size: map-deep-get($headings, 'body-2', 'size')
  font-weight: map-deep-get($headings, 'body-2', 'weight')
  padding: 0 $subheader-right-padding 0 $subheader-left-padding

  &--inset
    margin-left: $subheader-inset-margin
