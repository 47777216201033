@import './_variables.scss'

/* Theme */
+theme(v-system-bar) using ($material)
  background-color: map-deep-get($material, 'status-bar', 'regular')
  color: map-deep-get($material, 'text', 'secondary')

  .v-icon
    color: map-deep-get($material, 'text', 'secondary')

  &--lights-out
    background-color: map-deep-get($material, 'status-bar', 'lights-out') !important

.v-system-bar
  align-items: center
  display: flex
  font-size: $system-bar-font-size
  font-weight: $system-bar-font-weight
  padding: $system-bar-padding

  .v-icon
    font-size: $system-bar-icon-font-size
    margin-right: $system-bar-icon-margin-right

  &--fixed, &--absolute
    left: 0
    top: 0
    width: 100%
    z-index: 3

  &--fixed
    position: fixed

  &--absolute
    position: absolute

  &--window
    .v-icon
      font-size: $system-bar-window-icon-font-size
      margin-right: $system-bar-window-icon-margin-right
