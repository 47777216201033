@import '../../styles/styles.sass';

$time-picker-title-color: map-get($shades, 'white') !default;
$time-picker-title-btn-height: 70px !default;
$time-picker-landscape-title-btn-height: 55px !default;
$time-picker-ampm-title-margin-start: 8px !default;
$time-picker-ampm-title-margin-bottom: 6px !default;
$time-picker-ampm-title-margin: 0 0 $time-picker-ampm-title-margin-bottom $time-picker-ampm-title-margin-start !default;  // TODO: remove in 3.0
$time-picker-ampm-title-margin-ltr: $time-picker-ampm-title-margin !default;
$time-picker-ampm-title-margin-rtl: 0 $time-picker-ampm-title-margin-start $time-picker-ampm-title-margin-bottom 0 !default;
$time-picker-ampm-title-font-size: 16px !default;
$time-picker-landscape-ampm-title-margin: 16px 0 0 !default;
$time-picker-number-font-size: 16px !default;
$time-picker-indicator-size: 40px !default;
$time-picker-clock-padding: 10px !default;
$time-picker-clock-max-width: 290px !default;
$time-picker-clock-hand-height: calc(50% - 4px) !default;
$time-picker-clock-hand-width: 2px !default;
$time-picker-clock-hand-left: calc(50% - 1px) !default;
$time-picker-clock-center-size: 8px !default;
$time-picker-clock-end-size: 10px !default;
$time-picker-clock-end-top: -4px !default;
$time-picker-clock-inner-hand-height: 14px !default;
$time-picker-clock-inner-offset: 27px !default;
$time-picker-ampm-padding: 10px !default;
$time-picker-clock-end-border-width: 2px !default;
$time-picker-clock-end-border-style: solid !default;
$time-picker-clock-end-border-color: inherit !default;
