// Imports
@import './_variables.scss'

// Theme
+theme(v-banner) using ($material)
  .v-banner__wrapper
    border-bottom: 1px solid map-get($material, 'dividers')

// Block
.v-banner
  position: relative
  +elevationTransition()

// Element
.v-banner__actions
  align-items: center
  align-self: flex-end
  display: flex
  flex: 1 0 auto
  justify-content: flex-end
  margin-bottom: -$banner-y-padding / 2

  +ltr()
    margin-left: $banner-actions-start-margin

  +rtl()
    margin-right: $banner-actions-start-margin

  & > * // Margin between actions
    +ltr()
      margin-left: $banner-actions-margin

    +rtl()
      margin-right: $banner-actions-margin

.v-banner__content
  align-items: center
  display: flex
  flex: 1 1 auto
  overflow: hidden

.v-banner__text
  line-height: $banner-line-height

.v-banner__icon
  display: inline-flex
  flex: 0 0 auto

  +ltr()
    margin-right: $banner-start-padding

  +rtl()
    margin-left: $banner-start-padding

.v-banner__wrapper
  align-items: center
  display: flex
  flex: 1 1 auto

  +ltr()
    padding: $banner-y-padding $banner-end-padding $banner-y-padding $banner-start-padding

  +rtl()
    padding: $banner-y-padding $banner-start-padding $banner-y-padding $banner-end-padding

// Modifiers
.v-banner--single-line
  .v-banner__actions
    margin-bottom: 0
    align-self: center

  .v-banner__text
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .v-banner__wrapper
    padding-top: $banner-y-padding / 2
    padding-bottom: $banner-y-padding / 2

.v-banner--has-icon
  .v-banner__wrapper
    +ltr()
      padding-left: $banner-icon-padding

    +rtl()
      padding-right: $banner-icon-padding

.v-banner--is-mobile
  .v-banner__actions
    flex: 1 0 100%
    margin-left: 0
    margin-right: 0
    padding-top: $banner-mobile-actions-top-padding

  .v-banner__wrapper
    flex-wrap: wrap
    padding-top: $banner-mobile-top-padding

    +ltr()
      padding-left: $banner-mobile-start-padding

    +rtl()
      padding-right: $banner-mobile-start-padding

  &.v-banner--has-icon
    .v-banner__wrapper
      padding-top: $banner-mobile-multiline-padding

  &.v-banner--single-line
    .v-banner__actions
      flex: initial
      padding-top: 0

      +ltr()
        margin-left: $banner-mobile-singleline-padding

      +rtl()
        margin-right: $banner-mobile-singleline-padding

    .v-banner__wrapper
      flex-wrap: nowrap
      padding-top: $banner-mobile-padding

  .v-banner__icon
    +ltr()
      margin-right: $banner-icon-padding

    +rtl()
      margin-left: $banner-icon-padding

  .v-banner__content
    +ltr()
      padding-right: $banner-content-padding

    +rtl()
      padding-left: $banner-content-padding

    .v-banner__wrapper
      flex-wrap: nowrap
      padding-top: $banner-mobile-padding
