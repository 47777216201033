@import '../../styles/styles.sass';

$overflow-active-slot-border-radius: $border-radius-root $border-radius-root 0 0 !default;
$overflow-append-inner-height: 48px !default;
$overflow-append-inner-width: 42px !default;
$overflow-append-prepend-margin-bottom: 12px !default;
$overflow-append-prepend-margin-top: 12px !default;
$overflow-dense-input-margin-x: 16px !default;
$overflow-dense-slot-height: 38px !default;
$overflow-focused-active-border-radius: $border-radius-root !default;
$overflow-focused-active-slot-box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28) !default;
$overflow-focused-active-slot-elevation: 2 !default;
$overflow-input-slot-border-width: 2px 0 !default;
$overflow-label-margin-x: 16px !default;
$overflow-label-top: calc(50% - 10px) !default;
$overflow-margin-top: 12px !default;
$overflow-menu-content-box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28) !default;
$overflow-menu-content-select-list-border-radius: 0 0 $border-radius-root $border-radius-root !default;
$overflow-segmented-input-slot-border-width: thin 0 !default;
$overflow-segmented-selections-btn-height: 48px !default;
$overflow-segmented-selections-btn-margin-x: -16px !default;
$overflow-selection-comma-margin-x: 16px !default;
$overflow-slot-height: 48px !default;
$overflow-editable-select-slot-padding: 8px 16px !default;
