@import '../../styles/styles.sass';

$alert-border-opacity: 0.26 !default;
$alert-border-radius: $border-radius-root !default;
$alert-border-width: 4px !default;
$alert-dense-border-width: medium !default;
$alert-font-size: 16px !default;
$alert-icon-size: 24px !default;
$alert-margin: 16px !default;
$alert-outline: thin solid currentColor !default;
$alert-padding: 16px !default;
$alert-prominent-icon-font-size: 32px !default;
$alert-prominent-icon-size: 48px !default;
