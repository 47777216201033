@import './_variables.scss'
@import './_mixins.sass'

// Element
.v-list-group .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon
    align-self: center
    margin: 0
    min-width: $list-group-header-icon-min-width
    justify-content: flex-end

.v-list-group--sub-group
  align-items: center
  display: flex
  flex-wrap: wrap

.v-list-group__header
  &.v-list-item--active:not(:hover):not(:focus):before
    opacity: 0

.v-list-group__items
  flex: 1 1 auto

  .v-list-item,
  .v-list-group__items
    overflow: hidden

.v-list-group--active > .v-list-group__header
  > .v-list-group__header__append-icon .v-icon
    transform: rotate(-180deg)

  &.v-list-group__header--sub-group
    > .v-list-group__header__prepend-icon .v-icon
      transform: rotate(-180deg)


  .v-list-item,
  .v-list-item__content,
  .v-list-group__header__prepend-icon .v-icon
    color: inherit

.v-list-group--sub-group
  .v-list-item__action,
  .v-list-item__avatar,
  .v-list-item__icon
    &:first-child
      +ltr()
        margin-right: $list-group-sub-group-child-margin

      +rtl()
        margin-left: $list-group-sub-group-child-margin

  .v-list-group__header
    +ltr()
      padding-left: $list-group-sub-group-header-margin

    +rtl()
      padding-right: $list-group-sub-group-header-margin


  .v-list-group__items
    .v-list-item
      +ltr()
        padding-left: $list-group-items-item-padding

      +rtl()
        padding-right: $list-group-items-item-padding

  &.v-list-group--active
    .v-list-item__icon.v-list-group__header__prepend-icon .v-icon
      transform: rotate(-180deg)

.v-list-group--no-action
  > .v-list-group__items > .v-list-item
    +ltr()
      padding-left: $list-group-no-action-item-padding

    +rtl()
      padding-right: $list-group-no-action-item-padding

  &.v-list-group--sub-group
    > .v-list-group__items > .v-list-item
      +ltr()
        padding-left: $list-group-no-action-sub-group-item-padding

      +rtl()
        padding-right: $list-group-no-action-sub-group-item-padding

.v-list--dense
  .v-list-group--sub-group .v-list-group__header
    +ltr()
      padding-left: $list-group-dense-sub-group-header-padding

    +rtl()
      padding-right: $list-group-dense-sub-group-header-padding

  &.v-list--nav .v-list-group--no-action
    > .v-list-group__items > .v-list-item
      +ltr()
        padding-left: $list-group-nav-no-action-item-padding

      +rtl()
        padding-right: $list-group-nav-no-action-item-padding

    &.v-list-group--sub-group
      > .v-list-group__items > .v-list-item
        +ltr()
          padding-left: $list-group-sub-group-item-padding

        +rtl()
          padding-right: $list-group-sub-group-item-padding
