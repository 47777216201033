@import './_variables.scss'

.v-tooltip
  display: none

  &--attached
    display: inline

  &__content
    background: $tooltip-background-color
    color: $tooltip-text-color
    border-radius: $tooltip-border-radius
    font-size: $tooltip-font-size
    line-height: round($tooltip-font-size * 1.6)
    display: inline-block
    padding: $tooltip-padding
    position: absolute
    text-transform: initial
    width: auto
    opacity: 1
    pointer-events: none

    &--fixed
      position: fixed

    &[class*="-active"]
      transition-timing-function: $tooltip-transition-timing-function

    &[class*="enter-active"]
      transition-duration: $tooltip-transition-enter-duration

    &[class*="leave-active"]
      transition-duration: $tooltip-transition-leave-duration
