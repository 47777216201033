@import './_variables.scss'

.v-rating
  max-width: 100%
  white-space: nowrap

  .v-icon
    padding: $rating-padding
    border-radius: $rating-border-radius
    user-select: none
    outline: none

    &::after
      display: none

    +ltr()
      transform: scaleX(1)

    +rtl()
      transform: scaleX(-1)

  &--readonly
    .v-icon
      pointer-events: none

  &--dense
    .v-icon
      padding: $rating-dense-padding
