@import './_variables.scss'

// Theme
+theme(v-navigation-drawer) using ($material)
  background-color: map-get($material, 'navigation-drawer')

  &:not(.v-navigation-drawer--floating)
    .v-navigation-drawer__border
      background-color: map-get($material, 'dividers')

  .v-divider
    border-color: map-get($material, 'dividers')

// Block
.v-navigation-drawer
  -webkit-overflow-scrolling: touch
  display: flex
  flex-direction: column
  left: 0
  max-width: 100%
  overflow: hidden
  pointer-events: auto
  top: 0
  transition-duration: 0.2s
  transition-timing-function: map-get($transition, 'fast-out-slow-in')
  will-change: transform
  transition-property: transform, visibility, width
  +bootable()

  &.v-navigation-drawer--right
    &:after
      left: 0
      right: initial

  .v-list
    background: inherit

// Element
.v-navigation-drawer__border
  position: absolute
  right: 0
  top: 0
  height: 100%
  width: $navigation-drawer-border-width

.v-navigation-drawer__content
  height: 100%
  overflow-y: auto
  overflow-x: hidden

.v-navigation-drawer__image
  border-radius: inherit
  height: 100%
  position: absolute
  top: 0
  bottom: 0
  z-index: -1
  contain: strict
  width: 100%

  .v-image
    border-radius: inherit

// Modifier
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile
  max-height: 50%
  top: auto
  bottom: 0
  min-width: 100%

.v-navigation-drawer--right
  left: auto
  right: 0

  > .v-navigation-drawer__border
    right: auto
    left: 0

.v-navigation-drawer--absolute
  z-index: 1

.v-navigation-drawer--fixed
  z-index: 6

.v-navigation-drawer--absolute
  position: absolute

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile)
  z-index: 4

.v-navigation-drawer--fixed
  position: fixed

.v-navigation-drawer--floating:after
  display: none

.v-navigation-drawer--mini-variant
  overflow: hidden

  .v-list-item
    > *:first-child
      margin-left: 0
      margin-right: 0

    > *:not(:first-child)
      @include visually-hidden

  .v-list-group--no-action .v-list-group__items,
  .v-list-group--sub-group
    display: none

  &.v-navigation-drawer--custom-mini-variant
    .v-list-item
      justify-content: center

.v-navigation-drawer--temporary
  z-index: 7

.v-navigation-drawer--mobile
  z-index: 6

.v-navigation-drawer--close
  visibility: hidden

.v-navigation-drawer--is-mobile,
.v-navigation-drawer--temporary
  &:not(.v-navigation-drawer--close)
    +elevation($navigation-drawer-mobile-temporary-elevation)
