@import './_variables.scss'

.v-carousel
  overflow: hidden
  position: relative
  width: 100%

  &__controls
    align-items: center
    background: $carousel-controls-bg
    bottom: 0
    display: flex
    height: $carousel-controls-size
    justify-content: center
    list-style-type: none
    position: absolute
    width: 100%
    z-index: 1

    > .v-item-group
      flex: 0 1 auto

    &__item
      margin: $carousel-dot-margin

      .v-icon
        opacity: $carousel-dot-inactive-opacity

      &--active
        .v-icon
          opacity: $carousel-dot-active-opacity
          vertical-align: middle

      &:hover
        background: none

        .v-icon
          opacity: $carousel-dot-hover-opacity

// Element
.v-carousel__progress
  margin: 0
  position: absolute
  bottom: 0
  left: 0
  right: 0

.v-carousel .v-window-item
  display: block
  height: inherit
  text-decoration: none

// Modifier
.v-carousel--hide-delimiter-background
  .v-carousel__controls
    background: transparent

.v-carousel--vertical-delimiters
  .v-carousel__controls
    height: 100% !important
    width: $carousel-controls-size
