@import './_variables.scss'

+theme(v-divider) using ($material)
  border-color: map-get($material, 'dividers')

.v-divider
  display: block
  flex: 1 1 0px
  max-width: 100%
  height: 0px
  max-height: 0px
  border: solid
  border-width: thin 0 0 0
  transition: inherit

  &--inset:not(.v-divider--vertical)
    max-width: calc(100% - #{$divider-inset-margin})
    +ltr()
      margin-left: $divider-inset-margin

    +rtl()
      margin-right: $divider-inset-margin

  &--vertical
    align-self: stretch
    border: solid
    border-width: 0 thin 0 0
    display: inline-flex
    height: inherit
    min-height: 100%
    max-height: 100%
    max-width: 0px
    width: 0px
    vertical-align: text-bottom

    &.v-divider--inset
      margin-top: $divider-inset-margin-top
      min-height: 0
      max-height: $divider-inset-max-height
