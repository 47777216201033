@import '../../styles/styles.sass';

$card-actions-padding: 8px !default;
$card-adjacent-sibling-text-padding-top: 0 !default;
$card-border-radius: $border-radius-root !default;
$card-btn-margin-x: 8px !default;
$card-btn-padding: 0 8px !default;
$card-btn-small-margin-x: 4px !default;
$card-disabled-opacity: 0.6 !default;
$card-elevation: 2 !default;
$card-hover-elevation: 8 !default;
$card-link-focus-opacity: 0.08 !default;
$card-outlined-border-width: thin !default;
$card-overflow-wrap: break-word !default;
$card-raised-elevation: 8 !default;
$card-shaped-border-radius: 24px !default;
$card-subtitle-padding: 16px !default;
$card-text-font-size: map-deep-get($headings, 'subtitle-2', 'size') !default;
$card-text-font-weight: 400 !default;
$card-text-letter-spacing: map-deep-get($headings, 'subtitle-2', 'letter-spacing') !default;
$card-text-line-height: map-deep-get($headings, 'subtitle-2', 'line-height') !default;
$card-title-adjacent-sibling-subtitle-margin-top: -16px !default;
$card-title-adjacent-sibling-subtitle-text-padding-top: 0 !default;
$card-title-font-size: map-deep-get($headings, 'h6', 'size') !default;
$card-title-font-weight: map-deep-get($headings, 'h6', 'weight') !default;
$card-title-letter-spacing: map-deep-get($headings, 'h6', 'letter-spacing') !default;
$card-title-line-height: map-deep-get($headings, 'h6', 'line-height') !default;
$card-title-word-break: break-all !default;
$card-white-space: normal !default;
