@import '../../styles/styles.sass';

$timeline-divider-center: 50% !default;
$timeline-divider-width: 96px !default;

$timeline-item-padding: 24px !default;

$timeline-line-width: 2px !default;

$timeline-wedge-size: 10px !default;

$timeline-dot-small-size: 24px !default;
$timeline-dot-regular-size: 38px !default;
$timeline-dot-large-size: 52px !default;

$timeline-inner-dot-small-size: 18px !default;
$timeline-inner-dot-regular-size: 30px !default;
$timeline-inner-dot-large-size: 42px !default;
