@import '../../styles/styles.sass';

$avatar-margin-x: 16px !default;
$list-border-radius: $border-radius-root !default;
$list-dense-subheader-font-size: 0.75rem !default;
$list-dense-subheader-height: 40px !default;

$list-item-avatar-margin-y: 8px !default;
$list-item-avatar-horizontal-margin-x: -16px !default;

$list-item-icon-margin-y: 16px !default;

$list-item-min-height: 48px !default;
$list-item-two-line-min-height: 64px !default;
$list-item-three-line-min-height: 88px !default;

$list-item-title-font-size: map-deep-get($headings, 'subtitle-1', 'size') !default;
$list-item-subtitle-font-size: map-deep-get($headings, 'subtitle-2', 'size') !default;

$list-item-dense-title-font-size: 0.8125rem !default;
$list-item-dense-title-font-weight: 500 !default;
$list-padding: 8px 0 !default;
$list-dense-subheading-padding: 0 8px !default;
$list-nav-rounded-item-margin-bottom: 8px !default;
$list-nav-rounded-item-dense-margin-bottom: 4px !default;
$list-nav-padding-left: 8px !default;
$list-nav-padding-right: 8px !default;
$list-nav-item-padding: 0 8px !default;
$list-shaped-padding: 8px !default;
$list-subheader-padding-top: 0 !default;
$list-group-header-icon-min-width: 48px !default;
$list-group-sub-group-child-margin: 16px !default;
$list-group-sub-group-header-margin: 32px !default;
$list-group-items-item-padding: 40px !default;
$list-group-no-action-item-padding: 72px !default;
$list-dense-subheader-padding: 0 8px !default;
$list-nav-rounded-dense-item-margin-bottom: 4px !default;
$list-group-no-action-sub-group-item-padding: 88px !default;
$list-group-dense-sub-group-header-padding: 24px !default;
$list-group-nav-no-action-item-padding: 64px !default;
$list-group-sub-group-item-padding: 80px !default;
$list-item-padding: 0 16px !default;
$list-item-action-margin: 12px 0 !default;
$list-item-action-text-font-size: map-deep-get($headings, 'caption', 'size') !default;
$list-item-avatar-horizontal-margin: 8px !default;
$list-item-content-padding: 12px 0 !default;
$list-item-content-children-margin-bottom: 2px !default;
$list-item-icon-margin: 16px 0 !default;
$list-item-child-last-type-margin: 16px !default;
$list-item-child-min-width: 24px !default;
$list-item-title-subtitle-line-height: 1.2 !default;
$list-item-avatar-first-child-margin: 16px !default;
$list-item-action-icon-margin: 32px !default;
$list-dense-icon-height: 24px !default;
$list-dense-icon-margin: 8px !default;
$list-dense-min-height: 40px !default;
$list-item-dense-title-line-height: 1rem !default;
$list-item-dense-two-line-min-height: 60px !default;
$list-item-dense-three-line-min-height: 76px !default;
$list-dense-content-padding: 8px 0 !default;
$list-item-two-line-icon-margin-bottom: 32px !default;
$list-item-three-line-avatar-action-margin: 16px !default;
