@import './_variables.scss'

.v-progress-circular
  position: relative
  display: inline-flex
  vertical-align: middle
  justify-content: center
  align-items: center

  svg
    width: 100%
    height: 100%
    margin: auto
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 0

  &--indeterminate
    svg
      animation: $progress-circular-rotate-animation
      transform-origin: center center
      transition: $process-circular-intermediate-svg-transition

    .v-progress-circular__overlay
      animation: $progress-circular-rotate-dash
      stroke-linecap: round
      stroke-dasharray: 80, 200
      stroke-dashoffset: 0px

  &__info
    align-items: center
    display: flex
    justify-content: center

  &__underlay
    stroke: $progress-circular-underlay-stroke
    z-index: 1

  &__overlay
    stroke: currentColor
    z-index: 2
    transition: $progress-circular-overlay-transition

@keyframes progress-circular-dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0px

  50%
    stroke-dasharray: 100, 200
    stroke-dashoffset: -15px

  100%
    stroke-dasharray: 100, 200
    stroke-dashoffset: -125px

@keyframes progress-circular-rotate
  100%
    transform: rotate(360deg)
