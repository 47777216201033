@import './_variables.scss'

.v-color-picker__canvas
  $root: &
  position: relative
  overflow: hidden
  contain: strict

  &-dot
    position: absolute
    top: 0
    left: 0
    width: $color-picker-canvas-dot-size
    height: $color-picker-canvas-dot-size
    background: transparent
    border-radius: 50%
    box-shadow: $color-picker-canvas-dot-box-shadow

    &--disabled
      box-shadow: $color-picker-canvas-dot-disabled-box-shadow

    #{$root}:hover &
      will-change: transform
