@import './_variables.scss'

// Theme
+theme(v-picker__title) using ($material)
  background: map-deep-get($material, 'picker', 'title')

+theme(v-picker__body) using ($material)
  background: map-deep-get($material, 'picker', 'body')

.v-picker
  border-radius: $picker-border-radius
  contain: layout style
  display: inline-flex
  flex-direction: column
  vertical-align: top
  position: relative

.v-picker--full-width
  display: flex
  width: 100%

  > .v-picker__body
    margin: initial

.v-picker__title
  color: map-get($shades, 'white')
  border-top-left-radius: $picker-border-radius
  border-top-right-radius: $picker-border-radius
  padding: $picker-title-padding

.v-picker__title__btn
  transition: $primary-transition

  &:not(.v-picker__title__btn--active)
    opacity: $picker-inactive-btn-opacity
    cursor: pointer

    &:hover:not(:focus)
      opacity: 1

.v-picker__title__btn--readonly
  pointer-events: none


.v-picker__title__btn--active
    opacity: $picker-active-btn-opacity

.v-picker__body
  height: auto
  overflow: hidden
  position: relative
  z-index: 0

  flex: 1 0 auto
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 auto

  > div
    width: 100%

    &.fade-transition-leave-active
      position: absolute

.v-picker--landscape
  .v-picker__title
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    width: $picker-landscape-title-width
    position: absolute
    top: 0
    height: 100%
    z-index: 1

    +ltr()
      left: 0

    +rtl()
      right: 0

  .v-picker__body:not(.v-picker__body--no-title),
  .v-picker__actions:not(.v-picker__actions--no-title)
    +ltr()
      margin-left: $picker-landscape-title-width
      margin-right: 0

    +rtl()
      margin-right: $picker-landscape-title-width
      margin-left: 0
