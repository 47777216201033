@import './_variables.scss'

@mixin firefox()
  @-moz-document url-prefix()
    @content

.v-content
  +bootable()

  display: flex
  flex: 1 0 auto
  max-width: 100%
  transition: $content-transition

  &__wrap
    flex: 1 1 auto
    max-width: 100%
    position: relative

  +firefox()
    @media print
      display: block
