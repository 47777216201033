@import '../../styles/styles.sass';
@import '../../styles/tools/_functions.sass';

$btn-active-opacity: 0.18 !default;
$btn-border-radius: $border-radius-root !default;
$btn-focus-opacity: 0.24 !default;
$btn-font-weight: 500 !default;
$btn-hover-opacity: 0.08 !default;
$btn-icon-font-size: 18px !default;
$btn-icon-padding: 12px !default;
$btn-letter-spacing: .0892857143em !default;
$btn-outline-border-width: thin !default;
$btn-rounded-border-radius: 28px !default;
$btn-text-transform: uppercase !default;
$btn-transition-duration: 0.28s !default;
$btn-transition-fn: map-get($transition, 'fast-out-slow-in') !default;
$btn-transition: opacity 0.2s map-get($transition, 'ease-in-out') !default;

$btn-sizes: () !default;
$btn-sizes: map-deep-merge(
  (
    'x-small': 20,
    'small': 28,
    'default': 36,
    'large': 44,
    'x-large': 52
  ),
  $btn-sizes
);

$btn-font-sizes: () !default;
$btn-font-sizes: map-deep-merge(
  (
    'x-small': .625rem,
    'small': .75rem,
    'default': .875rem,
    'large': .875rem,
    'x-large': 1rem
  ),
  $btn-font-sizes
);

$fab-sizes: () !default;
$fab-sizes: map-deep-merge(
  (
    'x-small': 32,
    'small': 40,
    'default': 56,
    'large': 64,
    'x-large': 72
  ),
  $fab-sizes
);

$fab-icon-sizes: () !default;
$fab-icon-sizes: map-deep-merge(
  (
    'x-small': 18,
    'small': 24,
    'default': 24,
    'large': 28,
    'x-large': 32
  ),
  $fab-icon-sizes
);
