@import './_variables.scss'

// Transition
.bottom-sheet-transition
  &-enter
    transform: translateY(100%)

  &-leave-to
    transform: translateY(100%)

// Block
.v-bottom-sheet.v-dialog
  align-self: flex-end
  border-radius: 0
  flex: 0 1 auto
  margin: 0
  overflow: visible

  &.v-bottom-sheet--inset
    max-width: $bottom-sheet-inset-width

    @media #{map-get($display-breakpoints, 'xs-only')}
      max-width: none
