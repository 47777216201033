@import './_variables.scss'

.v-speed-dial
  position: relative
  z-index: $speed-dial-z-index

  &--absolute
    position: absolute

  &--fixed
    position: fixed

  &--fixed,
  &--absolute
    z-index: 4

    & > .v-btn--floating
      margin: 0

  &--top
    top: map-get($grid-gutters, 'lg')

  &--bottom
    bottom: map-get($grid-gutters, 'lg')

  &--left
    left: map-get($grid-gutters, 'lg')

  &--right
    right: map-get($grid-gutters, 'lg')

  &--direction
    &-left,
    &-right
      .v-speed-dial__list
        height: 100%
        top: 0
        padding: 0 $speed-dial-padding

    &-top,
    &-bottom
      .v-speed-dial__list
        left: 0
        width: 100%

    &-top
      .v-speed-dial__list
        flex-direction: column-reverse
        bottom: 100%

    &-right
      .v-speed-dial__list
        flex-direction: row
        left: 100%

    &-bottom
      .v-speed-dial__list
        flex-direction: column
        top: 100%

    &-left
      .v-speed-dial__list
        flex-direction: row-reverse
        right: 100%

/** Elements */
.v-speed-dial__list
  align-items: center
  display: flex
  justify-content: center
  padding: $speed-dial-padding 0
  position: absolute

  .v-btn
    margin: $speed-dial-button-margin

/** Modifiers */
.v-speed-dial:not(.v-speed-dial--is-active) .v-speed-dial__list
  pointer-events: none
