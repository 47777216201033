// Imports
@import './_variables.scss'

// Theme
+theme(v-data-table) using ($material)
  background-color: map-get($material, 'cards')
  color: map-deep-get($material, 'text', 'primary')

  .v-data-table__divider
    border-right: thin solid map-get($material, 'dividers')

  &.v-data-table--fixed-header
    thead th
      background: map-get($material, 'cards')
      box-shadow: inset 0 -1px 0 map-get($material, 'dividers')

  thead
    tr
      &:last-child th
        border-bottom: thin solid map-get($material, 'dividers')

      th
        color: map-deep-get($material, 'text', 'secondary')

  tbody
    tr
      &:not(:last-child)
        td,
        th
          &:not(.v-data-table__mobile-row)
            border-bottom: thin solid map-get($material, 'dividers')

          &:last-child
            border-bottom: thin solid map-get($material, 'dividers')

      &.active
        background: map-deep-get($material, 'table', 'active')

      &:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
        background: map-deep-get($material, 'table', 'hover')

// Block
.v-data-table
  table
    width: 100%
    border-spacing: 0

  td, th
    padding: 0 16px

  th
    user-select: none
    font-size: $data-table-regular-header-font-size
    height: $data-table-regular-header-height

    +ltr()
      text-align: left

    +rtl()
      text-align: right

  td
    font-size: $data-table-regular-row-font-size
    height: $data-table-regular-row-height

// Elements
.v-data-table__wrapper
  overflow-x: auto
  overflow-y: hidden

.v-data-table__progress
  height: auto !important

  .v-progress-linear
    position: absolute

  th
    height: auto !important
    border: none !important
    padding: 0
    position: relative

// Modifiers
.v-data-table--dense
  td
    height: $data-table-dense-row-height
  th
    height: $data-table-dense-header-height

.v-data-table--fixed-height
  .v-data-table__wrapper
    overflow-y: auto

.v-data-table--fixed-header
  .v-data-table__wrapper
    overflow-y: auto

  thead
    th
      border-bottom: 0px !important
      position: sticky
      top: 0
      z-index: 2

    tr:nth-child(2)
      th
        top: $data-table-regular-header-height

  // Account for scroll bar
  .v-data-footer
    +ltr()
      margin-right: $data-table-scroll-bar-width

    +rtl()
      margin-left: $data-table-scroll-bar-width

.v-data-table--fixed.v-data-table--dense
  thead
    tr:nth-child(2)
      th
        top: $data-table-dense-header-height
