@import '../../styles/styles.sass';

$data-table-border-radius: $border-radius-root !default;
$data-table-dense-header-height: 32px !default;
$data-table-dense-row-height: 24px !default;
$data-table-expanded-content-box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75) !default;
$data-table-header-mobile-select-chip-height: 24px !default;
$data-table-header-mobile-select-margin-bottom: 8px !default;
$data-table-header-mobile-select-max-width: 56px !default;
$data-table-header-sort-badge-height: 18px !default;
$data-table-header-sort-badge-min-height: 18px !default;
$data-table-header-sort-badge-min-width: 18px !default;
$data-table-header-sort-badge-width: 18px !default;
$data-table-mobile-row-header-font-weight: 600 !default;
$data-table-mobile-row-min-height: 48px !default;
$data-table-regular-header-height: 48px !default;
$data-table-regular-row-height: 48px !default;
$data-table-row-group-children-td-height: 35px !default;
$data-table-scroll-bar-width: 17px !default;
$edit-dialog-content-padding: 0 16px !default;
$edit-dialog-actions-padding: 8px !default;
$data-table-regular-header-font-size: map-deep-get($headings, 'caption', 'size') !default;
$data-table-regular-row-font-size: map-deep-get($headings, 'subtitle-2', 'size') !default;
